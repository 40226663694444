import React from 'react'
import "./Homepage.css"
import SocialMediaLinks from './social'
import Carousel from './Mainhomepage'
import Mainhomepage from './Mainhomepage'
import Effect from './Effect'

import ImageWithText from './Lasthomepage'
import Footer from './footer'
import { Link } from 'react-router-dom'

function Homepage() {
  return (
    <div>
        <SocialMediaLinks/>
      <ul className="nav nav-pills">

      <Link to="/">
  <img
    src="./logo.png"
    alt="company logo"
    width="200"
    height="90"
    style={{ marginRight: '100px' }}
  />
</Link>
     
      <li className="nav-item dropdown">
    <a className="nav-link dropdown-toggle" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false">PrimeStar</a>
    <ul className="dropdown-menu">
     <Link className="dropdown-item" to="/aboutus">About US</Link>
<Link className="dropdown-item" to="/why_we">Why Choose Us</Link>
<Link className="dropdown-item" to="/contact">Contact US</Link>
      {/* <li><hr className="dropdown-divider"></li> */}
      {/* <li><a className="dropdown-item" href="#">Separated link</a></li> */}
    </ul>
  </li>
  
  <li className="nav-item dropdown">
    <a className="nav-link dropdown-toggle" data-bs-toggle="dropdown" role="button" aria-expanded="false">Innovations</a>
    <ul className="dropdown-menu">
      <Link className="dropdown-item" to ="/Iot">IoT</Link>
      <a className="dropdown-item" href="/analytics_bigdata">Analytics & Big Data</a>
      <a className="dropdown-item" href="/Cloud Computing">Cloud Computing</a>
      {/* <li><hr className="dropdown-divider"></li> */}
      <a className="dropdown-item" href="/business_intelligence">Business Intelligence Tools</a>
    </ul>
  </li>
  <li className="nav-item dropdown">
    <a className="nav-link dropdown-toggle" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false">SERVERS</a>
    <ul className="dropdown-menu">
      <a className="dropdown-item" href="/bi_analytics">BI Analytics</a>
      <a className="dropdown-item" href="/bi_analytics_data">Big Data Consulting Services</a>
      <a className="dropdown-item" href="/testing">Testing</a>
      <a className="dropdown-item" href="/eai">EAI Tools</a>
      <a className="dropdown-item" href="/Delivery">Delivery Model</a>
      <a className="dropdown-item" href="/Customized Services">Customized Services</a>

      {/* <li><hr className="dropdown-divider"></li> */}
     
    </ul>
  </li>
  <li className="nav-item dropdown">
    <a className="nav-link dropdown-toggle" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false">OUR WORK</a>
    <ul className="dropdown-menu">
      <a className="dropdown-item" href="/Devops">DevOps</a>
      <a className="dropdown-item" href="/Microsoft_Solutions">Microsoft Solutions</a>
      <a className="dropdown-item" href="/lineof_business">Line of Business Areas</a>
     
      <a className="dropdown-item" href="/webdevelop"> Web Apps Development</a>
      <a className="dropdown-item" href="/Mobiledevelopment">Mobile Development</a>

      {/* <li><hr className="dropdown-divider"></li> */}
     
    </ul>
  </li>
  <li className="nav-item">
  <Link className="nav-link " to='/Client'>CLIENTS</Link>
  </li>
  <li className="nav-item">
  <Link className="nav-link " to='/TRAINING'>TRAINING</Link>
</li>
<li className="nav-item">
<Link className="nav-link " to='/aboutus'>WHAT WE DO?</Link>
</li>
</ul>
<Mainhomepage/>
<div>
<h2 className="innovationsHeader">OUR INNOVATIONS</h2>
  {/* <h2 style={{
    fontSize: '40px',
    marginLeft: '10px',
    marginRight: '10px',
    marginTop: '20px',
    backgroundImage: 'linear-gradient(to right, red, yellow)',
    padding: '5px',
    display: 'block',
    textAlign: 'center'
  }}>OUR INNOVATIONS</h2> */}
  <h1 className="innovationsSecret">The secret to our success is our ability to provide unparalleled exposure in extremely competitive markets.</h1>

  {/* <h1 style={{
    fontSize: '40px',
    marginLeft: '20px',
    marginRight: '20px',
    marginTop: '20px',
    fontWeight: 'bold',
    marginBottom: '30px',
    textAlign: 'center'
  }}>The secret to our success is our ability to provide unparalleled exposure in extremely competitive markets.</h1>
   */}
  <Effect />
</div>
<ImageWithText/>
< Footer/>
    </div>
  )
}

export default Homepage
