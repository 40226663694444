import React, { useState, useEffect } from 'react';
import { FaFacebook, FaTwitter, FaInstagram } from 'react-icons/fa';
import './social.css'; // You can create a CSS file for styles

function SocialMediaLinks() {
  const [showMessage, setShowMessage] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setShowMessage((prevShowMessage) => !prevShowMessage);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  return (
    <ul className="social-links">
      {showMessage && <h1>WELCOME TO PrimeStar</h1>}
      <li><a href="#"><FaFacebook /></a></li>
      <li><a href="#"><FaTwitter /></a></li>
      <li><a href="#"><FaInstagram /></a></li>
      {/* Add more social media links and icons */}
    </ul>
  );
}

export default SocialMediaLinks;
